// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.github {
  background: linear-gradient(to right top, #65dfc9, #6cdbeb);
  border-radius: 2rem;
  color: white;
  padding: 1rem;
  position: relative;
  overflow: hidden;

  transition: transform 1s ease-in-out;
}

.github.up {
  transform: translateY(-50px);
}

.github img {
  position: absolute;
  top: 3%;
  right: -10%;
}
.github h2 {
  width: 40%;
  color: white;
  font-weight: 600;
}

.ripple {
  width: 60px;
  height: 60px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  animation: rippleEffect 1s ease infinite;
}

@keyframes rippleEffect {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  100% {
    transform: scale(20);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./packages/alexandria-app/src/components/dashboard/GitHubSection.css"],"names":[],"mappings":"AAAA;EACE,2DAA2D;EAC3D,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;;EAEhB,oCAAoC;AACtC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,WAAW;AACb;AACA;EACE,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE;IACE,mBAAmB;IACnB,YAAY;EACd;EACA;IACE,oBAAoB;IACpB,UAAU;EACZ;AACF","sourcesContent":[".github {\n  background: linear-gradient(to right top, #65dfc9, #6cdbeb);\n  border-radius: 2rem;\n  color: white;\n  padding: 1rem;\n  position: relative;\n  overflow: hidden;\n\n  transition: transform 1s ease-in-out;\n}\n\n.github.up {\n  transform: translateY(-50px);\n}\n\n.github img {\n  position: absolute;\n  top: 3%;\n  right: -10%;\n}\n.github h2 {\n  width: 40%;\n  color: white;\n  font-weight: 600;\n}\n\n.ripple {\n  width: 60px;\n  height: 60px;\n  background-color: white;\n  position: absolute;\n  border-radius: 50%;\n  animation: rippleEffect 1s ease infinite;\n}\n\n@keyframes rippleEffect {\n  0% {\n    transform: scale(0);\n    opacity: 0.5;\n  }\n  100% {\n    transform: scale(20);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
